//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {
    Component,
    Vue
} from 'nuxt-property-decorator'
@Component({
  middleware:['login']
})
export default class Login extends Vue {
    created() {

        this.form = this.$form.createForm(this, {
            name: 'normal_login'
        });
    }
    handleSubmit(e) {
        const that = this;
        e.preventDefault();
        that.form.validateFields((err, values) => {
            if (!err) {
                console.log('Received values of form: ', values);
                that.$auth
                    .loginWith(that.$auth.options.defaultStrategy, {
                        data: {
                            email: values.email,
                            password: values.password,
                        },
                    })
                    .then((res) => {
                        if (res&&res.status==200&&res.data&&res.data.token) {
                            that.$message.success("Login Success !");
                            if (values.rememberMe) {
                                that.$auth.$storage.setCookie(
                                    `_remember.${that.$auth.options.defaultStrategy}`,
                                    that.$auth.strategy.token.get(), {
                                        maxAge: 2147483647
                                    }
                                );
                            }
                            that.$router.push("/");
                        }else{
                          that.$message.error("Unable to Login !");
                        }

                    })
                    .catch((err) => {
                        console.log("res1err", err);
                        that.$message.error("Login Failed !");
                        // if (
                        //     err.response &&
                        //     err.response.data &&
                        //     err.response.data.message
                        // ) {
                        //     that.$message.error(err.response.data.message);

                        // } else {
                        //     that.$message.error("login failed !");
                        // }
                    })

            }
        });
    }
}
