var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pagecontainer"},[_c('a-card',{staticClass:"login-form "},[_c('div',{staticClass:"my2"},[_c('h5',[_vm._v("Login")])]),_vm._v(" "),_c('a-form',{attrs:{"form":_vm.form},on:{"submit":_vm.handleSubmit}},[_c('a-form-item',[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'email',
          { rules: [
           {
                type: 'email',
                message: 'The input is not valid E-mail!',
              },
          { required: true, message: 'Please input your Email!' }] } ]),expression:"[\n          'email',\n          { rules: [\n           {\n                type: 'email',\n                message: 'The input is not valid E-mail!',\n              },\n          { required: true, message: 'Please input your Email!' }] },\n        ]"}],attrs:{"placeholder":"Email"}},[_c('a-icon',{staticStyle:{"color":"rgba(0,0,0,.25)"},attrs:{"slot":"prefix","type":"mail"},slot:"prefix"})],1)],1),_vm._v(" "),_c('a-form-item',[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'password',
          { rules: [{ required: true, message: 'Please input your Password!' }] } ]),expression:"[\n          'password',\n          { rules: [{ required: true, message: 'Please input your Password!' }] },\n        ]"}],attrs:{"type":"password","placeholder":"Password"}},[_c('a-icon',{staticStyle:{"color":"rgba(0,0,0,.25)"},attrs:{"slot":"prefix","type":"lock"},slot:"prefix"})],1)],1),_vm._v(" "),_c('a-form-item',[_c('a-checkbox',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'remember',
          {
            valuePropName: 'checked',
            initialValue: true,
          } ]),expression:"[\n          'remember',\n          {\n            valuePropName: 'checked',\n            initialValue: true,\n          },\n        ]"}]},[_vm._v("\n                    Remember me\n                ")]),_c('br'),_vm._v(" "),_c('a-button',{staticClass:"btBig login-form-button",attrs:{"type":"primary","html-type":"submit","block":""}},[_vm._v("\n                    SignIn\n                ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }